// src/pages/Contact.js
import React from 'react';
import MainContent from '../MainContent';


const Contact = () => {
  return (
    <div>
      <MainContent />
    </div>
  );
};

export default Contact;
