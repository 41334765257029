// src/CreateJsonPage.js
import React, { useState } from 'react';

const CreateJsonPage = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [date, setDate] = useState('');
  const [writer, setWriter] = useState('');

  const handleSaveJson = () => {
    if (!title) {
      alert('Title is required');
      return;
    }

    const data = {
      title: title,
      content: content,
      date: date || new Date().toLocaleDateString(),
      writer: writer || 'Said T.',
    };

    fetch('/save-json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          alert('File saved successfully');
        } else {
          alert('Error saving file');
        }
      })
      .catch(error => {
        console.error('Error saving JSON:', error);
        alert('Error saving file');
      });
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Create JSON File</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Title</label>
        <input
          type="text"
          className="border p-2 w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Content</label>
        <textarea
          className="border p-2 w-full"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Date</label>
        <input
          type="date"
          className="border p-2 w-full"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Writer</label>
        <input
          type="text"
          className="border p-2 w-full"
          value={writer}
          onChange={(e) => setWriter(e.target.value)}
        />
      </div>
      <hr className="my-4" />
      <h3 className="text-xl font-semibold mb-4">Preview</h3>
      <pre className="bg-gray-100 p-4 rounded">
        {JSON.stringify({ title, content, date, writer }, null, 2)}
      </pre>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded"
        onClick={handleSaveJson}
      >
        Save JSON
      </button>
    </div>
  );
};

export default CreateJsonPage;
