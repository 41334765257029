// src/pages/About.js
import React from 'react';

const About = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">About Page</h2>
      <p>Learn more about us on this page.</p>
    </div>
  );
};

export default About;
