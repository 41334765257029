// src/MainContent.js
import React, { useState, useEffect } from 'react';

const MainContent = () => {
  const [data, setData] = useState({ title: '', content: '', date: '', writer: '' });

  useEffect(() => {
    fetch('./data.json')
      .then(response => response.json())
      .then(jsonData => setData(jsonData))
      .catch(error => console.error('Error fetching JSON:', error));
  }, []);

  return (
    <main className="flex-grow p-4">
      <h2 className="text-2xl font-semibold mb-4">{data.title}</h2>
      <p>{data.content}</p>
      <p>{data.date}</p>
      <p>{data.writer}</p>
    </main>
  );
};

export default MainContent;
