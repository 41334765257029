// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './index.css'; // Make sure to import the index.css for Tailwind
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Content from './pages/Content';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <MainContent />
        <Footer />
      </div>
    </Router>
  );
}

function MainContent() {
  const location = useLocation();

  React.useEffect(() => {

    let title = 'IPSec Inc.';
    switch (location.pathname) {
      case '/':
        title = 'Home | ' + title;
        break;
      case '/about':
        title = 'About | ' + title;
        break;
      case '/contact':
        title = 'Contact | ' + title;
        break;
      case '/content': 
        title = 'Create a content | ' + title;
        break;
      default:
        title = '404 | ' + title;
        break;
    }

    document.title = title;
    console.log('Current page:', location.pathname);
  }, [location]);

  return (
    <main className="flex-grow p-4">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/content" element={<Content />} />
      </Routes>
    </main>
  );
}

export default App;
